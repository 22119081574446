

















































































































































































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Board as BoardObject, NorthStar, Objective as ObjectiveObject} from "@/typescript/types";
import AddMilestone from "@/components/northstars/milestones/AddMilestone.vue";
import Milestone from "@/components/northstars/milestones/Milestone.vue";
import Objective from "@/components/board/objective/Objective.vue";
import NorthStarService from "@/NorthStarService";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import ConfirmMarkComplete from "@/components/general/ConfirmMarkComplete.vue";
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import NorthStarBoardSelector from "@/components/northstars/NorthStarBoardSelector.vue";
import Formatting from "@/components/chat/Formatting.vue";
import ChatMessageService from "@/ChatMessageService";
import GuidedCreateObjective from "@/components/board/objective/GuidedCreateObjective.vue";
import {Container, Draggable} from "vue-smooth-dnd";
import sortBy from "lodash/sortBy";
import {SingleNorthStar, UpdateMilestoneMutation} from "@/graphql/NorthStar";
import {WorkspaceQuery} from "@/graphql/workspace";
import WorkableItem from "@/components/general/WorkableItem.vue";
import UpdateNorthStar from "@/components/northstars/UpdateNorthStar.vue";
import Template from "@/components/workspace/Template.vue";
import ObjectiveLists from "@/components/objectives/ObjectiveLists.vue";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import CreateObjectiveTrigger from "@/components/objectives/CreateObjectiveTrigger.vue";
import NorthStarPieChart from "@/components/northstars/NorthStarPieChart.vue";

@Component({
  components: {
    NorthStarPieChart,
    CreateObjectiveTrigger,
    Template,
    UpdateNorthStar,
    WorkableItem,
    NorthStarBoardSelector,
    Milestone,
    AddMilestone,
    Objective,
    ConfirmDelete,
    Formatting,
    GuidedCreateObjective,
    Container,
    Draggable,
    ObjectiveLists,
    ConfettiCanon
  },
  apollo: {
    getNorthStar: {
      query: SingleNorthStar,
      fetchPolicy: 'network-only',
      variables() {
        return {
          goal_id: this.$props.id
        }
      },
      result(result: any) {
        this.northstar = result.data.getNorthStar;
        if(this.$store.state.newlyCreatedNortstarInBoard != this.northstar.id) {
          this.goalShouldCollapse();
        }else{
          this.goalCollapsed = false;
          this.$store.commit('set_newly_created_northstar_in_board', null);
        }

        this.nsIsPublic = this.northstar.is_public;

        if (this.northstar.description) {
          this.nsDescription = this.northstar.description;
        }
        if (this.northstar.name) {
          this.nsName = this.northstar.name;
        }
        if(this.northstar.status) {
          this.nsStatus = this.northstar.status;
          this.nsInitialStatus = this.northstar.status;
        }
        if(this.northstar.due_date) {
          this.nsDueDate = this.northstar.due_date;
        }
        if(this.northstar.start_date) {
          this.nsStartDate = this.northstar.start_date;
        }
        if(this.northstar.color){
          this.nsColor = this.northstar.color
        }
        //@ts-ignore
        if(this.northstar.milestones?.length > 0){
          this.milestones = this.filterBoardMilestone;
        }

      }

    }
  }
})
export default class NorthStarDetailed extends Vue {
  goalId: string = this.$route.params.goal_id;
  addActivityActive: boolean = false;
  addMilestoneActive: boolean = false;
  nsName: string | null = null;
  nsDescription: string | null = null;
  nsInitialStatus: string | null = null;
  nsStatus: string | null = null;
  nsColor: string | null = null;
  nsDueDate: Date | null = null;
  nsStartDate: Date | null = null;
  nsIsPublic: boolean = false;
  service: NorthStarService | null = null;
  chatService: ChatMessageService | null = null;
  milestones: any | null = null;
  changeThemeActive: boolean = false;

  goalCollapsed: boolean = (this.$store.state.autoGenUserData) ? false : true;

  editDescriptionActive: boolean = false;
  editTitleActive: boolean = false;

  roomBlock: boolean = false;

  objectiveDueDate: Date | null = null;

  northstar: NorthStar | null = null;

  isStatusChangedToCompleted: boolean = false;

  addNewObjectiveActive: boolean = false;
  expandCompleted: boolean = false;

  isNsNameFocused: boolean = false;

  colors: Array<string> = [
    /*'#754373', */
    '#773f75',
    '#bd5fb4',
    '#374ea9',
    '#4a677d',
    '#776015',
    '#7b7343',
    '#316863',
    '#2e825f',
    '#4e6068',
    '#66737a',
    '#717171',
    '#434343',
    '#654C4F',
    '#5B507A',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
    'No color'
  ];

  goalDescriptionArray: Array<string> = [
    'This goal is owned by XX, and in order to complete it we need to do YY',
    'This goal is owned by XX, and in order to complete it we need to do YY'
  ];

  @Prop({default: undefined, required: false})
  board!: BoardObject;
  @Prop({default: false, required: true})
  onmodal!: Boolean;

  @Prop({default: true, required: false})
  showMilestones!: Boolean;

  @Prop({default: true, required: false})
  editable!: Boolean;

  @Prop({default: undefined, required: true})
  id!: String;

  @Prop({default: false, required: false})
  showCompleted!: boolean

  get shouldOpenShareUrl() {

    if(this.$route.name != 'board-layout') {
      return false;
    }
    return true;

  }

  get nsColorWithDefault() {
    if(this.nsColor) {
      return this.nsColor;
    } else {
      return '#696969';
    }
  }

  get disableDeleteButton(){
    if(this.$store.state.workspace.my_role === 'guest'){
      return true;
    }
    return false;
  }

  get filterBoardMilestone(){
    if(this.$props.board && this.board.pid && this.northstar){
      return sortBy(this.northstar?.milestones?.filter((milestone: any)=>{
        if(milestone.boards.length > 0 && milestone.boards.some((board: BoardObject) => board.pid === this.board.pid)){
          return milestone;
        }else{
          return;
        }
      }),['order'])
    }
    return sortBy(this.northstar?.milestones,['order']);
  }

  get completedObjectives() {
    return this.northstar?.objectives?.filter((objective: ObjectiveObject) => {
      return objective.status === 'completed';
    })
  }

  get isNorthstarDueDateInvalid() {
    if (!this.nsDueDate || !this.nsStartDate) {
      return false; // No error if one of the dates is not set
    }
    return new Date(this.nsDueDate) < new Date(this.nsStartDate);
  }

  get notCompletedObjectives() {
    return sortBy(this.northstar?.objectives?.filter((objective: ObjectiveObject) => {
      return objective.status != 'completed';
    }), ['order'])
  }

  get northStarRoom() {
    if(this.northstar && this.northstar.boards && this.northstar.boards.length > 0) {
      return this.northstar.boards[0].name;
    } else {
      return null;
    }
  }

  get truncatedNorthStarRoom() {
    // @ts-ignore
    return this.northStarRoom.length > 25
    // @ts-ignore
      ? this.northStarRoom.substring(0, 25) + '...'
      : this.northStarRoom;
  }

  goalShouldCollapse() {
    if(this.northstar && this.northstar.objectives && this.northstar.objectives.length > 0 && this.$props.onmodal == false) {
      this.goalCollapsed = false;
      return true;
    }else if(this.$props.onmodal) {
      this.goalCollapsed = false;
    }

  }

  get completedMilestones() {
    return this.filterBoardMilestone.filter((milestone: any) => {
      return milestone.status === 'completed';
    })
  }

  requestParentOpenInModal() {
    if(!this.onmodal) {
      this.$emit('open-in-modal', true);
    }
  }

  toggleDescriptionChange() {
    this.editDescriptionActive = !this.editDescriptionActive;
    if(this.editDescriptionActive) {
      if(document.querySelector('#goalDescription') !== null) {
        // @ts-ignore
        document.querySelector('#goalDescription').addEventListener("paste", function (e) {
          e.preventDefault();
          // @ts-ignore
          let text = e.clipboardData.getData("text/plain");
          document.execCommand("insertHTML", false, text);
        });
      }
      this.$nextTick(function () {
        // @ts-ignore
        this.$refs.goalDescription.innerHTML = this.nsDescription;
        //@ts-ignore ToDo: fix type issue
        this.$refs.goalDescription.focus();
      });
    }
  }

  handleNsNameFocus() {
    this.isNsNameFocused = true;
  }

  handleNsNameBlur() {
    setTimeout(() => {
      this.isNsNameFocused = false;
    }, 200);
  }

  updateDescription() {
    // @ts-ignore
    this.nsDescription = this.$refs.goalDescription.innerHTML;
    this.updateNorthStar();
    this.toggleDescriptionChange();
  }

  cancelDescriptionEditing() {
    // @ts-ignore
    this.nsDescription = this.northstar.description;
    this.toggleDescriptionChange();
  }

  editTitle() {
    this.editTitleActive = true;
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.nsTitleInput.focus();
    });
  }

  openDeleteModal() {
    this.$buefy.modal.open({
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to delete this goal?'
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.deleteNorthStar();
          }
        }
      },
      width: '500px',
      parent: this,
    });
  }

  closeModal() {
  
    // @ts-ignore
    this.$parent.close();
    
    this.$emit('close');
  }

  

  milestoneCreated(value: any) {
    this.$props.northstar!.milestones.push(value.milestone);
    //this.milestones.push(value.milestone);
    this.addMilestoneActive = false;
  }

  milestoneDeleted(id: string){

    let index = this.milestones.findIndex((milestone: any)=>milestone.id === id);

    this.milestones.splice(index,1);
  }

  deleteNorthStar(): void {
    if(this.northstar) {
      this.service?.deleteNorthStar(this.northstar.id).then(() => {
        this.$events.fire('north-star-delete', this.northstar?.id);
        //@ts-ignore
        this.$parent.close();
      });
    }
  }

  updateNorthStar(): void {
    if(this.northstar) {
      this.service?.updateNorthStarDescription(this.northstar.id, this.nsName, this.nsDescription, this.nsStatus, this.nsDueDate, this.nsStartDate, this.nsColor, this.nsIsPublic).then((res: any) => {

        this.nsDescription = res.description;
        this.nsStatus = res.status;
        this.nsDueDate = res.due_date;
        this.nsStartDate = res.start_date;
        this.nsName = res.name;
        this.nsColor = res.color;
        this.nsIsPublic = res.is_public

        this.$events.fire('northstars-updated-my-northstar', res)
        this.$events.fire('northstars-updated-for-northstar-completed-count', res)

      }).finally(() => {
        this.isNsNameFocused = false;
        this.editTitleActive = false;
        this.$buefy.toast.open({
          message: 'Updated!',
          position: 'is-bottom-right',
          type: 'is-black',
          
        });
        if (this.nsStatus === 'completed') {
          if(this.$props.board) {
            this.chatService?.sendBoardMessage(this.$props.board.id, 'Yay!! Excellent news! <b>'+this.$store.state.me.name+ '</b> marked <b>"' + this.nsName + '"</b> as completed! Well done! Now, don\'t forget to celebrate 🎉 🎉');
          }

        }
        this.$events.fire('northstar-color-change', {northstar_id: this.northstar!.id, color: this.nsColor});
        
      })
    }
  }

  confirmStatusChange() {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmMarkComplete,
      props: {
        deleteItemName: "Nice work! 💪",
        deleteParagraph: "Please confirm that you want to mark the goal as completed"
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if(value === true) {
            this.setStatus('completed');
          }
        }
      },
      width: '500px',
    });
  }

  setStatus(status: string){
    this.nsStatus = status;
    if(status === 'completed') {
      this.isStatusChangedToCompleted = true; 
    }else{
      this.isStatusChangedToCompleted = false;
    }
    this.updateNorthStar();
  }

  openDatePicker(type: string) {
    let setDate = new Date();
    setDate.setHours(17,0,0);
    let checkDate = (type == 'start') ? this.nsStartDate : this.nsDueDate;
    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      props: {
        'setDate': (checkDate) ? checkDate : setDate,
        'removeDueDate': true
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
          if(type == 'start') {
            this.nsStartDate = value;
          }else {
            this.nsDueDate = value;
          }

          this.updateNorthStar();

        }
      },
    });
  }

  applyFormat(type: string) {
    // @ts-ignore
    document.execCommand(type);
    // @ts-ignore
    let e = document.createEvent('HTMLEvents');
    // @ts-ignore
    e.initEvent('input', true, true);
    // @ts-ignore
    this.$refs.oDescription.dispatchEvent(e);
  }

  onDrop(dropResult: any): void {
    if (this.milestones) {
      let {removedIndex, addedIndex, payload} = dropResult
      if (removedIndex === null && addedIndex === null || !payload) return;


      if (!this.milestones) {
        return;
      }


      if (removedIndex != null && addedIndex != null) {
        let realIndex = this.getRealDraggedIndex(payload);
        this.milestones.splice(realIndex, 1);
        this.milestones.splice(addedIndex, 0, payload);
        this.reindexOrderContainer(this.milestones);

      }
    }
  }

  getChildPayload(index: number) {
    if (this.milestones) {
      return this.milestones[index]
    }
  }

  getGhostParent() {
    return document.body;
  }

  getRealDraggedIndex(payloadObj: any): number {
    let locallyMilestone = payloadObj;
    //@ts-ignore
    return this.milestones?.findIndex((milestone: any) => {
      return milestone.id === locallyMilestone.id
    });
  }

  reindexOrderContainer(milestones: any): void {
    let updateMilestones = new Array();

    milestones.forEach((milestone: any, index: number) => {
      let clone = Object.assign({}, milestone)
      clone.order = index;

      updateMilestones.push(clone)
    });
    this.updateMilestone(updateMilestones);
    this.milestones = updateMilestones;
    this.$forceUpdate();

  }

  updateMilestone(milestones: any){
    milestones.forEach((milestone: any) => {
      this.$apollo
        .mutate({
          mutation: UpdateMilestoneMutation,
          variables: {
            id: milestone.id,
            name: milestone.name,
            description: milestone.description,
            status: milestone.status,
            reward: milestone.reward,
            order: milestone.order
          },
          refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }],
        })
        .then(() => {
          //
        })
      ;
    })
  }

  setColor(color: string) {
    this.changeThemeActive = !this.changeThemeActive;
    if (color === 'No color') {
      this.nsColor = null;
    } else {
      this.nsColor = color;
    }
    this.updateNorthStar();
  }

  mounted() {
    this.service = new NorthStarService(this.$apollo);
    this.chatService = new ChatMessageService(this.$apollo);

    this.$events.listen('board-milestone-added', (eventData) => {
      if(this.northstar && this.northstar.milestones && this.northstar.id === eventData.northStarId) {
        let index = this.northstar?.milestones?.findIndex((milestone: any) => milestone.id === eventData.milestone.id);

        if(index < 0){
          if(eventData.northStarId === this.northstar.id) {
            this.northstar.milestones.push(eventData.milestone);
          }

        }

        this.addMilestoneActive = false;
      }

    });

    this.$events.listen('northstar_board_selected', eventData => {
      if(this.northstar  && this.northstar.id === eventData.id) {
        this.northstar = eventData;
      }

    })


  }

  checkNorthstarObjective(northStars: any) {

    let data = [];
    if(northStars && northStars.length > 0) {
      data = northStars!.filter((goal: any) => {
        return goal.id === this.northstar?.id
      })
    }


    if(data.length > 0){
      return true;
    }else{
      return false;
    }

  }

  getChildPayloadObjective(index: number) {
    if (this.notCompletedObjectives) {
      return this.notCompletedObjectives[index]
    }
  }

  getGhostParentObjective() {
    return document.body;
  }

  onDragStart(_dragResult: any): void {
  
  }

  onDropObjective(_dropResult: any, _containerName: any): void {
   
  }

  addDescriptionText(index: number) {
    this.editDescriptionActive = true;
    if(this.nsDescription) {
      this.nsDescription = this.nsDescription  + `<p>${this.goalDescriptionArray[index]}</p>`;
    }else{
      this.nsDescription = `<p>${this.goalDescriptionArray[index]}</p>`;
    }
    //@ts-ignore
    this.$refs.goalDescription.innerHTML = this.nsDescription;
  }

  copyObjective(objective_id: string) {
    this.service?.copyObjectiveForNorthStar(this.northstar!.id, objective_id, this.$store.state.board.id).then((res: any) => {
      this.$events.fire('objective-created-from-northstar', {objective: res.data.copyObjectiveForNorthstar, northStarId: this.northstar!.id });
    });
  }

  copyMilestone(milestone_id: string) {
    this.service?.copyMilestoneForNorthStar(this.northstar!.id, milestone_id, this.$store.state.board.id);
  }

  makePublicPrivate(isPublic: boolean) {
    this.nsIsPublic = isPublic;
    this.updateNorthStar();
  }


  @Watch('filterBoardMilestone')
  milestoneChanged(newMilestones: any){
    this.milestones = newMilestones;
  }


}
